import React, { useState } from "react";
import styles from "./popup.module.css";

const Popup = () => {
  const [showPopup, setShowPopup] = useState(true);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div>
      {showPopup && (
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <h2>Flex GenAI Guidelines and Terms of Use</h2>
            <p>
              Employees must review and accept the<a href="https://flextronics365.sharepoint.com/sites/GenAI/SitePages/Our-GenAI-Guidelines.aspx">Flex GenAI Guidelines</a>before
              using this application. Flex GenAI is intended for internal use
              only. <br /> Please contact<a href="mailto:cortexsupport@flex.com">Cortex Support</a>with questions.
            </p>
            <button className={styles.btn} onClick={togglePopup}>
              Yes, I accept terms
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popup;
