import { Outlet, NavLink, Link } from "react-router-dom";
import { useState, useEffect } from "react";

import Logo from "../../assets/flexW.png";
import { Dialog, Stack, TextField } from "@fluentui/react";
import styles from "./Layout.module.css";
import { useOktaAuth } from "@okta/okta-react";

const Layout = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const handleLogin = () => oktaAuth.signInWithRedirect();

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (authState?.isAuthenticated) {
        try {
          const user = await oktaAuth.getUser();
          user.name && setName(user.name);
          user.email && setEmail(user.email);
          
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      }
    };

    fetchUserInfo();
  }, [authState?.isAuthenticated, oktaAuth]);

  return (
    <div className={styles.layout}>
      {!authState || !authState?.isAuthenticated ? (
        <></>
      ) : (
        <div className={styles.hUser}>
          <h4>Hi, {name}</h4>
        </div>
      )}
      {!authState || !authState?.isAuthenticated ? (
        <div className={styles.chatRoot}>
          <div className={styles.chatContainer}>
            <div className={styles.chatEmptyState}>
              <img src={Logo} className={styles.chatIcon} aria-hidden="true" />
              <h1 className={styles.chatEmptyStateTitle}>
                {" "}
                Welcome to Flex GenAI
              </h1>
              <h2 className={styles.chatEmptyStateSubtitle}>
                Log in with your Okta account to continue
              </h2>
              <button className={styles.button} onClick={handleLogin}>
                Log in
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Outlet context={email} />
      )}
    </div>
  );
};

export default Layout;
